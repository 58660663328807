import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { NgxPermissionsService } from 'ngx-permissions';

@Directive({
  selector: '[trNgxPermissionsOnlyAnd]',
})
export class NgxPermissionsOnlyAndDirective implements OnInit {
  private permissionsToCheck: string[] = [];
  private isHidden = true;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private permissionsService: NgxPermissionsService
  ) {}

  @Input()
  public set trNgxPermissionsOnlyAnd(permissionsToCheck: string[]) {
    this.permissionsToCheck = permissionsToCheck;
    this.updateView();
  }

  public ngOnInit(): void {
    this.permissionsService.permissions$.subscribe(() => this.updateView());
  }

  private updateView(): void {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    return !this.permissionsToCheck.some(
      permissionToCheck =>
        !this.permissionsService.hasPermission(permissionToCheck)
    );
  }
}
