export type KeycloakRealm = 'isync2';

export enum KeycloakUrl {
  DEV = 'https://login.dev.televic-rail-demo.com',
}

export type DocumentationUrl = 'https://docs.dev.televic-rail-demo.com';

export type KeycloakClientId = 'isync2_ui_dev' | 'isync2_ui_local';

export type ApiBaseUrl = 'backend';

export interface IKeycloackConfig {
  realm: KeycloakRealm;
  url: KeycloakUrl;
  clientId: KeycloakClientId;
}

export interface IEnvironment {
  appName: string;
  production: boolean;
  keycloak: IKeycloackConfig;
  api: ApiBaseUrl;
  version: string;
  articleNumber: string;
  documentationUrl: DocumentationUrl;
}
