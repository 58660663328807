<a
  class="t-button"
  routerLink="/release-feature-flags"
  *ngxPermissionsOnly="['RELEASE_FEATURE_FLAG_MANAGEMENT']"
>
  {{ 'global.mainNavigationActions.action.releaseFeatureFlags' | translate }}
</a>

<ng-container *ngxPermissionsOnly="'LANGUAGE_SELECTION_RELEASE_FEATURE_FLAG'">
  <ng-container *ngIf="currentLanguage$ | async as currentLanguage">
    <button
      class="t-button icon inline"
      [ngClass]="{ active: currentLanguage === 'en' }"
      (click)="useLanguage('en')"
    >
      EN
    </button>

    <button
      class="t-button icon inline"
      [ngClass]="{ active: currentLanguage === 'fr' }"
      (click)="useLanguage('fr')"
    >
      FR
    </button>
  </ng-container>
</ng-container>

<button
  class="t-button"
  (click)="logout()"
>
  Logout
</button>
