import { getFunctionalityId } from '@base_app/core/routing/store/routing.selectors';
import { isSuccessfullyResolvedStateWithAsync } from '@base_app/shared/model/ngrx.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IFunctionality } from '../functionality.model';
import { UserFunctionalityState } from './user-functionalities.model';

export const getUserFunctionalitiesState =
  createFeatureSelector<UserFunctionalityState>('userFunctionality');

export const getUserFunctionalities = createSelector(
  getUserFunctionalitiesState,
  userFunctionalitiesState =>
    userFunctionalitiesState.functionalities.filter(
      functionality => functionality.enabled
    )
);

export const getUserFunctionalitiesLoadingStatus = createSelector(
  getUserFunctionalitiesState,
  userFunctionalitiesState => userFunctionalitiesState.pending
);

export const getUserFunctionalitiesIdsCollection = createSelector(
  getUserFunctionalities,
  functionalities => {
    if (!functionalities || !functionalities.length) {
      return null;
    }

    return functionalities.reduce((collection, functionality) => {
      collection[functionality.id] = functionality.id;

      return collection;
    }, {} as { [key: number]: number });
  }
);

export const areUserFunctionalitiesSuccessfullyResolved = createSelector(
  getUserFunctionalitiesState,
  isSuccessfullyResolvedStateWithAsync
);

export const getUserFunctionalityLocalizationPathById = (
  functionalityId: number
) =>
  createSelector(
    getUserFunctionalities,
    (functionalities: IFunctionality[]) =>
      findUserFunctionalityById(functionalities, functionalityId)
        ?.localizationPath ?? null
  );

export const getUserFunctionalityName = createSelector(
  getUserFunctionalities,
  getFunctionalityId,
  (functionalities, functionalityId) => {
    const functionality = findUserFunctionalityById(
      functionalities,
      functionalityId
    );

    return functionality?.name;
  }
);

export const findUserFunctionalityById = (
  functionalities: IFunctionality[],
  functionalityId: number | null
): IFunctionality | null => {
  if (!functionalityId || !functionalities) {
    return null;
  }

  return (
    functionalities.find(
      functionality => functionality.id === functionalityId
    ) || null
  );
};
