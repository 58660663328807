import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { PrimeNgAutocompleteCompleteMethodEvent } from '@base_app/shared/model/primeng.model';
import { SimpleChangesTyped } from '@base_app/shared/utils/angular.utils';
import { StringUtil } from '@base_app/shared/utils/string.utils';

@Component({
  selector: 'tr-autocomplete-dropdown',
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrls: ['./autocomplete-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteDropdownComponent),
      multi: true,
    },
  ],
})
export class AutocompleteDropdownComponent
  implements OnChanges, OnInit, ControlValueAccessor
{
  @Input() public placeholder = '';
  @Input() public fieldToDisplay = 'name';
  @Input() public propNamesToFilterBy: string[] = [];
  @Input() public shortenSelectedTo: number | null = null;
  @Input() public appendTo = 'body';
  @Input() public inputId = `autocomplete-${new Date()}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public itemsForAutocomplete: any[] | null = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public selected: EventEmitter<any[]> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public unselected: EventEmitter<any[]> = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public value: any[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public autocompleteItems: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public filteredAutocompleteItems: any[] = [];

  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onChange: (value: any[]) => void = () => {};

  public onTouched: () => void = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public writeValue(value: any[]): void {
    if (value) {
      this.value = value;
    }
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public ngOnInit(): void {
    if (this.propNamesToFilterBy.length === 0) {
      this.propNamesToFilterBy.push(this.fieldToDisplay);
    }
  }

  public ngOnChanges({
    itemsForAutocomplete,
  }: SimpleChangesTyped<AutocompleteDropdownComponent>): void {
    if (itemsForAutocomplete?.currentValue) {
      this.autocompleteItems = itemsForAutocomplete.currentValue;
    }
  }

  public filterAutocompleteItems(
    { query }: PrimeNgAutocompleteCompleteMethodEvent,
    propNamesToFilterBy: string[]
  ): void {
    this.filteredAutocompleteItems = StringUtil.filterByString(
      query,
      this.autocompleteItems,
      propNamesToFilterBy
    );
  }

  public selectAutocomplete(): void {
    this.onChange(this.value);
    this.selected.emit(this.value);
  }

  public unselectAutocomplete(): void {
    this.onChange(this.value);
    this.unselected.emit(this.value);
  }
}
