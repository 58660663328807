import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IFunctionality } from '@base_app/modules/functionality/store/functionality.model';
import { getUserFunctionalities } from '@base_app/modules/functionality/store/user-functionalities/user-functionalities.selectors';
import { isNotNullOrUndefined } from '@base_app/shared/utils/rxjs.util';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'tr-app-switcher',
  templateUrl: './app-switcher.component.html',
  styleUrls: ['./app-switcher.component.scss'],
})
export class AppSwitcherComponent implements OnInit {
  public functionalities$!: Observable<IFunctionality[]>;

  constructor(private router: Router, private store$: Store) {}

  public ngOnInit(): void {
    this.functionalities$ = this.store$.pipe(
      select(getUserFunctionalities),
      isNotNullOrUndefined(),
      filter(functionalities => functionalities.length > 0),
      map(functionalities =>
        functionalities.filter(functionality => !!functionality.routerLink)
      )
    );
  }

  public getFunctionalityUrl(functionality: IFunctionality): string {
    const operatorLink = this.router.url.split('functionality')[0];

    return `${operatorLink}/functionality/${functionality.routerLink}`;
  }
}
