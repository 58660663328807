import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { BlobErrorsInterceptor } from './interceptors/blob-errors.interceptor';
import { ErrorsInterceptor } from './interceptors/errors.interceptor';
import { MaintenanceInterceptor } from './interceptors/maintenance.interceptor';
import { SECURITY_EFFECTS } from './ngrx/security.effect';
import { securityReducers } from './ngrx/security.reducer';
import { appInitializer } from './utils/app.initializer';
import { keyCloakInitializer } from './utils/keycloak.initializer';

const appInitializerDependencies = new InjectionToken<(() => void)[]>(
  'appInitializerDependencies'
);

@NgModule({
  imports: [
    CommonModule,
    KeycloakAngularModule,
    StoreModule.forFeature('security', securityReducers),
    EffectsModule.forFeature([...SECURITY_EFFECTS]),
  ],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [HttpClient, appInitializerDependencies],
    },
    {
      provide: appInitializerDependencies,
      useFactory: (keycloak: KeycloakService) => {
        return [keyCloakInitializer(keycloak)];
      },
      deps: [KeycloakService],
    },
  ],
})
export class SecurityModule {}
