<div class="tr-card {{ styleClasses }}">
  <ng-content></ng-content>

  <i
    aria-hidden="true"
    [ngClass]="[iconClasses]"
  ></i>

  <h4
    class="t-h4"
    [pTooltip]="titles | joinBy: '':tooltipSeparator"
    [tooltipDisabled]="!showEllipsis"
  >
    <span
      *ngFor="
        let title of titles | slice: 0:maxTitles;
        let i = index;
        let last = last
      "
    >
      {{ title }}
      <span
        class="ellipsis"
        *ngIf="last && showEllipsis"
        >...</span
      >
    </span>
  </h4>
</div>
