<span
  *ngIf="text && text.length >= limit; else fullText"
  [pTooltip]="text"
>
  {{ text | shorten: limit:suffix }}
</span>

<ng-template #fullText>
  <span>
    {{ text }}
  </span>
</ng-template>
